<template>
  <div class="content">
    <div class="error-page">
      <img src="@/assets/img/404.png" alt="" />
      <h3>当前页面似乎受到三体射线的干扰</h3>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    console.log("log", this.$route);
  },
};
</script>
<style lang="scss" scoped>
.error-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
h3 {
  font-size: 18px;
  color: #979dac;
  margin-top: 20px;
}
</style>
