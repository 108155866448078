<template>
  <div class="content">
    <div class="goods-list" ref="view">333</div>
  </div>
</template>
<script>
// import "zhixin-libs/libs/main.css";
// import * as lib from "zhixin-libs/libs/quizList";
// import $ from "jquery";

export default {
  data() {
    return {
      goods: [],
      loading: true,
    };
  },
  mounted() {
    // var controller = lib.default.Controller;
    // var APP = lib.default.APP;
    // var view = controller({
    //   APP: APP,
    // });
    // $(this.$refs.view).html(view.$el);
  },
};
</script>
<style lang="scss" scoped>
.goods-list {
  margin: 0px -10px;
}
</style>
