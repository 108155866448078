<template>
  <div class="knowledge-video">
    <div class="video-nav">
      <div class="content video-title">
        <div class="video-nav-info">
          <img
            class="catalogue"
            @click="back"
            src="@/assets/img/st_icon_list_nor.svg"
            alt=""
          />
          <div class="catalogue-text">
            <span>{{ chapter }}</span>
            <img src="@/assets/img/icon_nextpage_dis.svg" alt="" />
          </div>
          <div class="catalogue-text">
            <span>{{ section }}</span>
            <img src="@/assets/img/icon_nextpage_dis.svg" alt="" />
          </div>
          <span class="current-catalogue">{{ title }}</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="video">
        <video
          id="player-container-id"
          preload="auto"
          playsinline
          webkit-playsinline
          style="width: 100%; height: 100%"
        ></video>
      </div>
      <div class="content share">
        <span>分享到:</span>
        <div
          class="share-icon"
          :class="item"
          v-for="item in sharePlat"
          @click="share(item)"
          :key="item"
        >
          <i class="fa" :class="'fa-' + item"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TCPlayer from "tcplayer.js";
import api from "@/plugins/api";
import "tcplayer.js/dist/tcplayer.min.css";
import { mobShare } from "@/utils/utils.js";
export default {
  name: "Knowledge-Video",
  data() {
    return {
      videoInfo: null,
      sharePlat: ["weixin", "qq", "qzone", "weibo"],
      chapter: "",
      section: "",
      title: "",
      tcPlayer: null,
      progressTime: null,
    };
  },
  methods: {
    share(item) {
      mobShare(item, {
        title: this.title,
      });
    },
    back() {
      this.$router.push({
        path: "/knowledge",
      });
    },
    getVideoInfo() {
      let params = {
        current_page: 1,
        video_id: this.$route.params.vid,
      };
      api.getLearningVideo(params).then((res) => {
        if (res.data.code === 1) {
          this.videoInfo = res.data;
          this.title = res.data.title;
          this.$nextTick(() => {
            this.init();
          });
        } else if (res.data.code === 20300) {
          this.$chiralConfirmMessage("请登录", () => {
            this.$router.push({ path: "/knowledge" });
          });
        }
      });
    },
    init() {
      let _this = this;
      this.tcPlayer = TCPlayer("player-container-id", {
        fileID: this.videoInfo.tx_video_id,
        appID: this.videoInfo.tx_app_id,
        autoplay: true, // 是否自动播放
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        plugins: {
          ContinuePlay: {
            // 开启续播功能
            auto: true,
          },
        },
        t: this.videoInfo.t,
        us: this.videoInfo.us,
        sign: this.videoInfo.sign,
      });
      this.tcPlayer.on("canplay", function () {
        _this.progressTime && window.clearInterval(_this.progressTime);
        _this.checkProgress(_this.tcPlayer, true);
        _this.keyDown();
      });
    },
    checkProgress(player, isH5) {
      let that = this;
      var duration = isH5 ? player.duration() : player.getDuration();
      var lastProgress = isH5
        ? (player.currentTime() / duration) * 100
        : (player.getPosition() / duration) * 100;
      this.progressTime = window.setInterval(function () {
        if (!isH5 && !player.getDuration) {
          window.clearInterval(that.progressTime);
          return false;
        }
        if (isH5 && !player.duration) {
          window.clearInterval(that.progressTime);
          return false;
        }
        var progress = isH5
          ? (player.currentTime() / duration) * 100
          : (player.getPosition() / duration) * 100;
        progress =
          progress % 10 < 5
            ? parseInt(progress / 10) * 10
            : parseInt(progress / 10) * 10 + 5;
        if (progress === 100) {
          window.clearInterval(that.progressTime);
          that.videoProgress(100);
        }
        if (progress !== 100 && progress > lastProgress) {
          that.videoProgress(progress);
          lastProgress = progress;
        }
      }, 2000);
    },
    videoProgress(progress) {
      var formdata = new FormData();
      formdata.append("video_id", this.videoInfo.video_id);
      formdata.append("progress", progress);
      api.videoProgress(formdata).then(() => {});
    },
    keyDown() {
      document.onkeydown = (e) => {
        //事件对象兼容
        let e1 =
          e || event || window.event || arguments.callee.caller.arguments[0];
        //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
        if (!this.tcPlayer) return;
        if (e1 && e1.keyCode == 37) {
          this.tcPlayer.currentTime(this.tcPlayer.currentTime() - 15);
          // 按下左箭头
        } else if (e1 && e1.keyCode == 39) {
          this.tcPlayer.currentTime(this.tcPlayer.currentTime() + 15);
          // 按下右箭头
        }
      };
    },
    // 获取知识树
    getSkillTree() {
      let params = {
        site_belong: "开罗尔",
        subject_type: this.$route.params.subject,
      };
      api.getSkillTree(params).then((res) => {
        if (res.data.code === 1) {
          this.handleCatalogue(res.data.skill_tree.nodes);
        }
      });
    },
    // 获取目录
    handleCatalogue(treeList) {
      let chapterId = this.$route.params.chapter;
      let sectionId = this.$route.params.section;
      treeList.forEach((item) => {
        if (Number(chapterId) === item.id) {
          this.chapter = item.text;
          item.nodes.forEach((section) => {
            if (Number(sectionId) === section.id) {
              this.section = section.text;
            }
          });
        }
      });
    },
  },
  mounted() {
    this.getVideoInfo();
    this.getSkillTree();
  },
  destroyed() {
    //页面销毁，同时也销毁video.js对象
    this.tcPlayer.dispose();
  },
};
</script>
<style lang="scss" scoped>
.knowledge-video {
  min-height: 400px;
  padding-bottom: 20px;
}
.share {
  min-height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 24px 23px 0px;
  background: #fff;
  color: rgba(51, 51, 51, 0.6);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 0px 0px 4px 4px;

  .share-icon {
    width: 26px;
    height: 26px;
    margin-left: 14px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    line-height: 26px;
    cursor: pointer;
  }
  .share-icon:nth-child(1) {
    margin-left: 3px;
  }
  .weixin {
    background: rgba(91, 196, 79, 1);
  }
  .qq {
    background: rgba(76, 175, 233, 1);
  }
  .weibo {
    background: rgba(245, 100, 103, 1);
  }
  .qzone {
    background: rgba(254, 190, 60, 1);
  }
  i {
    font-size: 18px;
  }
}
.video-nav {
  background: #fff;
  color: rgba(51, 51, 51, 0.5);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  .video-title {
    min-height: auto;
    padding: 18px 0px;
    .catalogue {
      margin-right: 8px;
      cursor: pointer;
    }
    .catalogue-text {
      display: inline;
      img {
        margin: 0px 17px;
      }
    }
    .current-catalogue {
      color: rgba(74, 54, 250, 1);
    }
  }
}
.video {
  margin-top: 20px;
  // min-height: auto;
  height: 613px;
  padding: 24px;
  background: #fff;
  border-radius: 4px 4px 0px 0px;
}
@media (max-width: 767px) {
  .video {
    padding: 0px 0px 0.5067rem 0px;
    height: 203px;
  }
  .share {
    font-size: 0.32rem;
    padding-right: 0.3733rem;
    padding-bottom: 0.4533rem;
    i {
      font-size: 0.32rem;
    }
    .share-icon {
      width: 0.4267rem;
      height: 0.4267rem;
      line-height: 0.4267rem;
    }
  }
}
</style>
