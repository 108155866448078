<template>
  <div class="knowledge-card" v-if="info">
    <div class="difficulty">难度: {{ info.difficult }}</div>
    <div class="title">{{ info.text }}</div>
    <div class="brief">{{ info.desp }}</div>
    <div class="progress">
      <el-progress
        v-if="info.video"
        :percentage="info.video.progress"
        :format="format"
      ></el-progress>
    </div>
  </div>
</template>
<script>
export default {
  name: "knowledge-card",
  props: ["info"],
  data() {
    return {
      format(percentage) {
        return `${percentage}%`;
      },
    };
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.knowledge-card {
  background: #ffffff;
  box-shadow: 0px -2px 16px 0px #f4f2ff;
  border-radius: 8px;
  padding-bottom: 16px;
  position: relative;
  cursor: pointer;
  .difficulty {
    background: #f4f2ff;
    border-radius: 28px 0px 0px 28px;
    font-size: 12px;
    font-weight: 400;
    color: #4a36fa;
    padding: 1px 8px 1px 6px;
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 9px;
  }
  .title {
    padding: 26px 0px 0px 12px;
    line-height: 1;
    width: 76%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
  }
  .brief {
    padding: 10px 0px 25px 12px;
    line-height: 1;
    width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: rgba(51, 51, 51, 0.6);
    min-height: 48px;
  }
  .progress {
    padding: 0px 0px 0px 12px;
    :deep .el-progress__text {
      font-size: 12px !important;
      color: #4a36fa;
    }
  }
}
</style>
