<template>
  <div class="content knowledge-content">
    <subject-nav
      :defaultSubject="defaultSubject"
      @change="changeSubject"
    ></subject-nav>
    <div class="knowledge-container">
      <ul class="nav-tabs" v-if="skillTree">
        <li
          class="nav-items"
          :class="
            checkedFirstLevelNodeObj.id === item.id ? 'nav-item-active' : ''
          "
          v-for="item in skillTree.nodes"
          :key="item.id"
          @click="changeNode(item)"
        >
          <div class="nav-items-text">{{ item.text }}</div>
        </li>
      </ul>
      <div class="tab-content" v-if="knowledgeList">
        <el-collapse
          v-model="activeKnowledgeId"
          @change="getKnowledgeInfo"
          accordion
        >
          <el-collapse-item
            :title="String(item.text)"
            :name="item.id"
            class="collapse-knowledge"
            v-for="item in knowledgeList"
            :key="item.id"
            v-loading="loading === item.id"
          >
            <div class="card-list" v-if="checkKnowledgeList.length">
              <div
                class="card-items"
                v-for="item in checkKnowledgeList"
                :key="item.id"
              >
                <knowledge-card
                  :info="item"
                  @click.native="jumpVideoBefore(item)"
                ></knowledge-card>
              </div>
            </div>
            <div class="card-list" v-else>
              <div class="knowledge-empty">
                <img src="@/assets/img/knowledge/empty.png" alt="" />
                <div class="empty-tips">暂无视频，敬请期待</div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/plugins/api";
import { mapGetters, mapActions } from "vuex";
import subjectNav from "@/components/subject-nav/Main.vue";
import knowledgeCard from "@/components/knowledge/Card.vue";
import { SUBJECT } from "@/utils/constants.js";

export default {
  name: "Knowledge",
  data() {
    return {
      defaultSubject: "PHY",
      skillTree: null,
      checkedFirstLevelNodeObj: "",
      // 右侧知识点列表
      knowledgeList: [],
      // 知识点抽屉默认展开项
      activeKnowledgeId: "",
      active_knowledge_id: "",
      // 知识点抽屉默认展开项获取的列表
      checkKnowledgeList: [],
      loading: 0,
      cacheList: null, //缓存知识点列表
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.sensorsTrack();
    });
  },
  methods: {
    ...mapActions(["getProfile"]),
    sensorsTrack() {
      if (window.sensors?.track) {
        window.sensors.track("NavigationBarView", {
          XueKe: SUBJECT[this.defaultSubject],
          ButtonName: "知识点",
        });
      }
    },
    // 切换左边的知识树
    changeNode(nodeObj) {
      this.handleDefaultKnowledge(nodeObj);
    },
    changeSubject(subjectValue) {
      this.defaultSubject = subjectValue;
      this.getSkillTree();
      this.sensorsTrack();
    },
    reset() {
      this.checkKnowledgeList = [];
      this.checkedFirstLevelNodeObj = "";
      this.knowledgeList = [];
    },
    // 切换右侧抽屉知识点
    getKnowledgeInfo(val) {
      if (!val) return;
      this.inquireCacheKnowledgeList(val);
    },
    // 获取知识树
    getSkillTree() {
      let params = {
        site_belong: "开罗尔",
        subject_type: this.defaultSubject,
      };
      api.getSkillTree(params).then((res) => {
        if (res.data.code === 1) {
          this.skillTree = res.data.skill_tree;
          if (this.skillTree.nodes && this.skillTree.nodes[0]) {
            this.handleDefaultKnowledge(this.skillTree.nodes[0]);
          } else {
            this.reset();
          }
        }
      });
    },
    // 展开知识点
    handleDefaultKnowledge(nodeObj) {
      this.checkedFirstLevelNodeObj = nodeObj;
      this.knowledgeList = nodeObj.nodes;
      this.inquireCacheKnowledgeList(nodeObj.nodes[0].id);
    },
    // 查询缓存知识点
    inquireCacheKnowledgeList(id) {
      this.activeKnowledgeId = id;
      this.active_knowledge_id = id;
      if (!this.cacheList) {
        this.cacheList = {};
        this.getLearning(id).then((res) => {
          this.cacheKnowledge(id, res);
        });
        return;
      }
      if (this.cacheList[id]) {
        this.checkKnowledgeList = this.cacheList[id];
      } else {
        this.getLearning(id).then((res) => {
          this.cacheKnowledge(id, res);
        });
      }
    },
    // 缓存知识点
    cacheKnowledge(id, data) {
      this.cacheList[id] = data;
    },
    // 获取知识点列表
    getLearning(id) {
      let params = {
        parent: id,
      };
      this.loading = this.activeKnowledgeId;
      return new Promise((resolve) => {
        api.getLearning(params).then((res) => {
          if (res.data.code === 1) {
            this.checkKnowledgeList = res.data.courses || [];
            resolve(res.data.courses);
          }
          this.loading = 0;
        });
      });
    },
    // 跳转视频
    jumpVideo(obj) {
      this.$router.push({
        name: "Knowledge-Video",
        params: {
          chapter: this.checkedFirstLevelNodeObj.id,
          section: this.active_knowledge_id,
          vid: obj.id,
          subject: this.defaultSubject,
        },
      });
    },
    jumpVideoBefore(obj) {
      this.jumpVideo(obj);
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated", "user"]),
  },
  mounted() {
    this.getSkillTree();
  },
  components: {
    "subject-nav": subjectNav,
    "knowledge-card": knowledgeCard,
  },
};
</script>
<style lang="scss" scoped>
.knowledge-content {
  padding: 38px 0px 44px;
}
.knowledge-container {
  display: flex;
  margin-top: 24px;
  .nav-tabs {
    background: #fff;
    width: 316px;
    min-width: 80px;
    margin-right: 9px;
    padding: 6px 0px;
    border-radius: 8px;
    .nav-items {
      padding: 16px 0px 16px 16px;
      font-size: 13px;
      font-weight: 400;
      color: #757880;
      position: relative;
      cursor: pointer;
      .nav-items-text {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
    @media (max-width: 767px) {
      .nav-items {
        padding: 13px 0px 13px 16px;
      }
    }
    .nav-item-active {
      color: rgba(74, 54, 250, 1);
      background: #fafbfc;
      border-radius: 2px;
      font-weight: 500;
    }
    .nav-item-active::after {
      content: "";
      display: block;
      width: 4px;
      height: 29px;
      background: rgba(74, 54, 250, 1);
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translate(0px, -50%);
      border-radius: 1px;
    }
  }
  @media (max-width: 767px) {
    .nav-tabs {
      width: 80px;
      min-width: 80px;
    }
  }
  .tab-content {
    flex: 1;
    max-width: 815px;
    overflow: hidden;
    .el-collapse {
      border-bottom: none;
    }
    .collapse-knowledge {
      margin-bottom: 20px;
      .card-list {
        display: flex;
        padding: 17px 8px 0px 3px;
        flex-wrap: wrap;
        .card-items {
          width: 31%;
          margin: 0px 9px 20px 9px;
        }
        @media (min-width: 768px) and (max-width: 1199px) {
          .card-items {
            width: 45%;
          }
        }
        @media (max-width: 767px) {
          .card-items {
            width: 100%;
          }
        }
        .knowledge-empty {
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          color: #c4c7d0;
          line-height: 16px;
          margin: 0px auto;
          img {
            width: 79px;
            margin-bottom: 15px;
          }
        }
      }
      :deep .el-collapse-item__header .el-collapse-item__arrow {
        font-size: 14px;
        color: white;
        font-weight: bold;
      }
    }
    :deep .el-collapse-item__wrap {
      border-radius: 0px 0px 8px 8px;
      border-bottom: none;
    }
    :deep .el-collapse-item__content {
      padding-bottom: 15px;
    }
    :deep .el-collapse-item__header {
      border-radius: 8px 8px 0px 0px;
      color: #ffffff;
      background: rgba(74, 54, 250, 0.5);
      padding: 10px 0px 10px 26px;
      height: auto;
      line-height: 0px;
      font-weight: bold;
    }
  }
}
</style>
